import MenuButton from "./button/MenuButton";
import style from "./Menu.module.scss";
import { useLocation } from "react-router-dom";
import { isIState } from "../navigation/TypeState";
import MenuItem from "./MenuItem";
import { PathListMenu } from "../router/Path";
import useMenuOpenClose from "./useMenuOpenClose";

export default function Menu() {
	const location = useLocation();
	const {closeMenu, isMenuOpen, switchMenuOpenClose} = useMenuOpenClose();

	return (
		<div className={style["menu-container"]}>
			<MenuButton
				isMenuOpen={isMenuOpen}
				switchMenuOpenClose={switchMenuOpenClose}
			/>
			{
				isIState(location.state)?
				<nav
					className={[style["menu"], ...isMenuOpen ? [] : [style["invisible"]]].join(" ")}
				>
					
					{
						PathListMenu.map(path =>
							<MenuItem
								key={path}
								closeMenu={closeMenu}
								path={path}
							/>
						)
					}
				</nav>
				:
				<></>
			}
		</div>
	);
}
