import style from "./Title.module.scss"
import useTitle from "../navigation/useTitle";
import { useEffect } from "react";

const WEB_SITE_AUTHOR = "Julien Ferté";

export default function Title() {
	const title = useTitle();
	
	useEffect(() => {
		document.title = title().title || WEB_SITE_AUTHOR;
	}, [title]);

	return <h1 className={style.title}>{title().title}</h1>;
}
