import style from "./MenuButton.module.scss";
import { ReactComponent as IconMenuSvg } from "./icon-menu.svg";

interface IMenuButtonProps {
	isMenuOpen: Boolean,
	switchMenuOpenClose: () => void,
}

export default function MenuButton(
	{isMenuOpen, switchMenuOpenClose} : IMenuButtonProps
) {
	return (
		<button
			className={
				[
					style["menu-button"],
					isMenuOpen? style["menu-is-opened"] : style["menu-is-closed"]
				].join(" ")
			}
			onClick={switchMenuOpenClose}
		>
			<IconMenuSvg />
		</button>
	);
}
