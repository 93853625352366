import { useCallback, useState } from "react";

interface IuseMenuOpenClose {
	isMenuOpen: Boolean,
	setMenuOpen: (a: Boolean) => void,
	switchMenuOpenClose: () => void,
	closeMenu: () => void,
}

export default function useMenuOpenClose(): IuseMenuOpenClose {
	const [isMenuOpen, setMenuOpen] = useState<Boolean>(false);

	const switchMenuOpenClose = useCallback(
		() => { setMenuOpen(! isMenuOpen); },
		[isMenuOpen, setMenuOpen]
	);

	const closeMenu = useCallback(
		() => { setMenuOpen(false); },
		[setMenuOpen]
	);

	return {
		isMenuOpen,
		setMenuOpen,
		switchMenuOpenClose,
		closeMenu,
	};
}
