import style from "./Grid.module.scss";
import Menu from "../menu/Menu";
import { Outlet } from "react-router-dom";
import Title from "../title/Title";
import P15n from "../p15n/P15n";

export default function Grid() {
	return <div className={style["content"]}>
		<Menu />
		<Title />
		<P15n />
		<Outlet />
	</div>;
}
