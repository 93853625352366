import Grid from "../grid/Grid";
import { Language } from "../l12n/Language";
import InitializeState from "../navigation/initialize-state/InitializeState";
import MissingPage from "../pages/missing-page/MissingPage";
import Welcome from "../pages/welcome/Welcome";
import { PathListMenu, PathToPage, SubPathTitle } from "./Path";

const Routes = [
	{
		path: "/",
		element: <Welcome />,
	},
	{
		path: "/",
		children: Object.values(Language).map(language => {
			const dictionary = require("../l12n/" + language + ".json");

			return {
				path: language,
				element: <Grid />,
				children: PathListMenu.map(path => {
					return {
							path: dictionary[path][SubPathTitle],
							element:
								<>
									<InitializeState language={language} path={path} />
									{PathToPage(path)}
								</>,
						};
					})
				};
			}
		)
	},
	{
		path: "*",
		element: <MissingPage />
	},
];

export default Routes;
