export enum Language {
	en = "en",
	fr = "fr",
};

export const DefaultLanguage = Language.fr;

export function LanguageName(language: Language) {
	switch(language) {
		case Language.fr:
			return "Français";
		case Language.en:
			return "English";
		default:
			return "";
	}
}
