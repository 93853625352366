import { Language, LanguageName } from "./Language";
import style from "./LanguagePicker.module.scss";
import { useCallback } from "react";
import useChangeStateLanguage from "../navigation/change-state/useChangeStateLanguage";
import { useTranslation } from "react-i18next";

export default function LanguagePicker() {
	const changeStateLanguage = useChangeStateLanguage();
	const { i18n, t } = useTranslation();

	const onLanguageSelect = useCallback(
		(event: any) => {
			changeStateLanguage(event.target.value);
		},
		[changeStateLanguage]
	);

	return (
		<select
			className={style["pick-language"]}
			onChange={onLanguageSelect}
			title={t("pick-language")}
			value={i18n.language}
		>
			{
				Object.values(Language).map(
					language =>
						<option
							key={language}
							title={LanguageName(language)}
							value={language}
						>
							{language.toUpperCase()}
						</option>
				)
			}
		</select>
	);
}
