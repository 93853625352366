import "./App.module.scss";
import "./l12n/i18n";
import Mobile from "./r12y/Mobile";
import Router from "./router/Router";

export default function App() {
	return <>
		<Mobile />
		<Router />
	</>;
};
