import LanguagePicker from "../l12n/LanguagePicker";
import style from "./P15n.module.scss";
import ThemeSwitcher from "../themes/ThemeSwitcher";

export default function P15n() {
	return (
		<div className={style["content"]}>
			<ThemeSwitcher />
			<LanguagePicker />
		</div>
	);
}
