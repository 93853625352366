import { useCallback } from "react";
import style from "./MenuItem.module.scss";
import { Path, SubPathTitle } from "../router/Path";
import useChangeStatePath from "../navigation/change-state/useChangeStatePath";
import { useTranslation } from "react-i18next";

interface IMenuItemProps {
	closeMenu: () => void,
	path: Path,
};

export default function MenuItem(
		{ closeMenu, path}: IMenuItemProps
	) {
	const changeStatePath = useChangeStatePath();
	const { t } = useTranslation();

	const changeLanguage = useCallback(
		() => {
			closeMenu();
			changeStatePath(path);
		},
		[changeStatePath, closeMenu, path]
	);

	return (
		<button
			className={style["menu-item"]}
			onClick={changeLanguage}
		>
			{t(path + "." + SubPathTitle)}
		</button>
	);
};
