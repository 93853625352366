import style from "./ThemeSwitcher.module.scss";
import { useTranslation } from "react-i18next";
import useTheme from "./useTheme";

export default function ThemeSwitcher() {
	const {switchTheme} = useTheme();
	const { t } = useTranslation();

	return (
		<button
			className={style["switch-theme"]}
			title={t("change-theme")}
			onClick={switchTheme}
		/>
	);
}