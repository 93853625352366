import { useCallback, useEffect, useState } from "react";
import Themes from "./Themes";
import getDocumentHtml from "../dom/getDocumentHtml";

function userPreferredTheme(): Themes {
	return matchMedia("(prefers-color-scheme: light)").matches? Themes.Light : Themes.Dark;
}

interface IuseTheme {
	theme: Themes,
	setTheme: (newTheme: Themes) => void,
	switchTheme: () => void
};

export default function useTheme() : IuseTheme {
	const [theme, setTheme] = useState<Themes>(userPreferredTheme());

	useEffect(
		() => {
			// Au chargement de l'application, le style par défaut qui est
			// défini afin d'éviter les flashs lors du reload est supprimé
			// afin de laisser les variables prendre le pas.
			// Utiliser !important dans CSS fonctionnerait pour la priorité
			// mais ça fait des flashes avec une vision intermittente de la
			// page en cours de chargement, ce qui est pire.
			getDocumentHtml().style.backgroundColor = "";
		}
	);

	useEffect(
		() => {
			const htmlClass = getDocumentHtml().classList;

			if(theme === Themes.Light)
				htmlClass.add("is-light");
			else
				htmlClass.remove("is-light");
		},
		[theme]
	);

	useEffect(
		() => {
			matchMedia("(prefers-color-scheme: light)").addEventListener(
				"change",
				(ev: MediaQueryListEvent) => {
					setTheme(ev.matches? Themes.Light : Themes.Dark);
				}
			);
		}
	);
	
	const switchTheme = useCallback(
		() => { setTheme(theme === Themes.Dark? Themes.Light : Themes.Dark); },
		[setTheme, theme]
	);

	return {theme, setTheme, switchTheme};
}
